<template>
  <div
    class="StationscoreBox item-b"
    v-loading="showLoad"
    element-loading-text="拼命加载中"
  >
    <FormItem
      :formList="formList"
      :btn="btn"
      @search="onSearch"
      @export="onExport"
      @reset="onReset"
      :btnObj="btnObj"
      :search="search"
      @changeRadio="radioHandle"
    />
    <Table
      ref="table"
      :tableList="tableList"
      @operation="onHandle"
      @reset="onReset"
      :tableData="tableData"
      @currentNum="current"
      :total="total"
      @cellclick="goRecord"
      :navtor="true"
    />
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="boxTitle">{{ tongjititle }}</div></el-col
        >
        <el-col :span="6"
          ><div>
            <el-date-picker
              class="custom-input"
              v-model="value3"
              type="year"
              value-format="yyyy"
              format="yyyy"
              :placeholder="timer"
              :picker-options="pickerOptions"
            >
            </el-date-picker></div
        ></el-col>
        <el-col :span="2"
          ><div>
            <el-button
              type="primary"
              class="item-primary"
              @click="searchClick(value3)"
              >查询</el-button
            >
          </div></el-col
        >
        <el-col :span="2"
          ><div>
            <el-button class="item-info" @click="logReset">重置</el-button>
          </div></el-col
        >
      </el-row>
      <div class="box">
        <div class="boxOne">
          <div class="boxOne1">
            <div class="boxOneTitle">
              <span class="boxOneTest">超载报警次数统计表</span
              ><span class="boxOneNum">{{ listNum.alarmNum }}次</span>
            </div>
            <Echart :option="ChartData1"></Echart>
          </div>
          <div class="boxOne2">
            <div class="boxOneTitle">
              <span class="boxOneTest">清运次数统计表</span
              ><span class="boxOneNum">{{ listNum.cleanNum }}次</span>
            </div>
            <Echart :option="ChartData2"></Echart>
          </div>
        </div>
        <div class="boxTwo">
          <div class="boxTwo1">
            <div class="boxOneTitle">
              <span class="boxOneTest">超载处理时长</span
              ><span class="boxOneNum"
                >{{ listNum.alarmProcessingTimeNum }}H</span
              >
            </div>
            <Echart :option="ChartData3"></Echart>
          </div>
          <div class="boxTwo2">
            <div class="boxOneTitle">
              <span class="boxOneTest">转运站分数排名</span
              ><span class="boxOneNum">第{{ Listcount }}名</span>
            </div>
            <div class="paiMing">
              <div class="paiBox">
                <div
                  class="paiMian"
                  v-for="(item, i) in tongLists"
                  :key="i"
                  @click="show(item.transferPointId, value3)"
                >
                  <div v-if="i == 0">
                    <span><img src="../assets/img/paione.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 1">
                    <span><img src="../assets/img/paithree.png" alt="" /></span>
                  </div>
                  <div v-else-if="i == 2">
                    <span><img src="../assets/img/paitwo.png" alt="" /></span>
                  </div>
                  <div class="paiNum" v-else>
                    <span> {{ i + 1 }}</span>
                  </div>
                  <div class="paiText">{{ item.transferPointName }}</div>
                  <div class="shu1" v-show="i > 0"></div>
                  <div class="shu2" v-show="i > 2"></div>
                  <div class="shu3" v-show="i > 3"></div>
                </div>
              </div>
            </div>
            <div class="dang"></div>

            <!-- <div class="wrap">
            <ul>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>5</li>
              <li>6</li>
              <li>7</li>
              <li>8</li>
              <li>9</li>
              <li>10</li>
              <li>11</li>
              <li>12</li>
            </ul>
            <div class="item"></div>
          </div> -->
            <div></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/request";
import Echart from "./Echart";
export default {
  name: "Stationscore",
  components: { Echart },
  data() {
    return {
      timer: "",
      eaitData: {},
      dialogVisible: false,
      value3: "",
      showLoad: true,
      ammoniaGas: "", //氨气
      ydrothion: "", //硫化氢
      curDay: "",
      formList: [
        {
          props: "transferPointName",
          el: "el-input",
          label: "转运站名称",
          placeholder: "请输入转运站名称",
        },
        // {
        //   props: "dateType",
        //   noClear: true,
        //   el: "el-radio-group",
        //   align: "right",
        //   options: [
        //     {
        //       value: "1",
        //       name: "月度",
        //     },
        //     {
        //       value: "2",
        //       name: "年度",
        //     },
        //   ],
        // },
      ],
      btnObj: {
        search: "查询",
        export: "导出",
        reset: "重置",
      },
      btn: "search,export,reset",
      tableList: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          prop: "transferPointName",
          label: "转运站名称",
          width: "",
        },
        {
          prop: "score",
          label: "转运站得分",
          width: "",
        },
        {
          prop: "alertsCount",
          label: "超载报警次数",
          width: "",
        },
        {
          prop: "processingHour",
          label: "超载处理时长(总计)",
          width: "",
        },
        {
          prop: "cleanCount",
          label: "清运次数",
          width: "",
        },
        {
          prop: "scoreTimeStr",
          label: "评分月度",
          width: "",
        },
        {
          prop: "scoreType",
          label: "总体评分",
          width: "",
        },
        {
          prop: "op",
          label: "操作",
          width: "150",
          btn: [
            {
              type: "text",
              name: "查看统计",
              prop: "edit",
            },
          ],
        },
      ],
      tableData: [],
      tongLists: [],
      listNum: {},
      Listcount: "",
      ChartData1: null, //折线
      ChartData2: null,
      ChartData3: null,
      page: 1,
      size: 10,
      total: 0,
      search: {
        transferPointName: "",
        dateType: "1",
      },
      tongjititle: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        },
      },
    };
  },
  mounted() {
    const time = new Date();
    this.timer = time.getFullYear() + "";
    this.value3 = this.timer
    this.getData(this.page, this.size, this.search);
    this.curDay = `${time.getFullYear()}-${
      time.getMonth() + 1
    }-${time.getDate()}`;
    // this.getWashRoomChartData();
    //  this.zhuLine()
  },
  methods: {
    handleClose(done) {
      done();
    },
    getData(page, size, val) {
      this.showLoad = true;
      axios
        .get(`/transferPointAssess/page/${page}/${size}`, {
          params: {
            ...val,
          },
        })
        .then((res) => {
          this.tableData = res.list;
          this.total = res.total;
          this.showLoad = false;
        });
    },
    onSearch(val) {
      this.search = val;
      this.getData(this.page, this.size, val);
    },
    searchClick(val) {
      this.show(this.eaitData.transferPointId, val);
    },
    // 重置
    logReset() {
      this.value3 = this.timer;
      this.show(this.eaitData.transferPointId, this.eaitData.year);
    },
    goRecord(data) {
      if (data.column.property == "transferPointName") {
        console.log(data);
        this.$router.push({
          path: "/alarmrecord",
          query: {
            params: data.row.transferPointName,
            paths: "/alarmrecord",
          },
        });
      }
    },

    radioHandle(val) {
      this.search = val;
      this.getData(this.page, this.size, val);
      if (val.dateType == 1) {
        this.tableList[6].label = "评分月度";
      } else if (val.dateType == 2) {
        this.tableList[6].label = "评分年度";
      }
    },
    onExport(val) {
      this.$export({
        url: "/transferPointAssess/export",
        method: "get",
        params: { ...this.search },
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a");
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "转运站绩效考核.xlsx";
        link.click();
        URL.revokeObjectURL(objectUrl); // 释放内存
      });
    },
    onHandle(data) {
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == "del") {
        // this.del(data.data);
      } else if (data.opVal == "edit") {
        this.edit(data.data);
      }
    },
    edit(data) {
      console.log(data);
      this.tongjititle = data.transferPointName;
      this.eaitData = data;
      this.show(data.transferPointId, data.year);
      this.dialogVisible = true;
    },
    onReset(val) {
      console.log(val);
      // this.dialogVisible = true;
      this.$refs.table.lastEmittedPage();
      this.getData(1, 10, val);
    },
    current(val) {
      this.page = val;
      this.getData(this.page, this.size, this.search);
    },
    show(transferPointId, year) {
      axios
        .get(
          `/detailsCount/transferPointCount?transferPointId=${transferPointId}&year=${year}`
        )
        .then((res) => {
          console.log(res);
          this.listNum = res;
          this.tongLists = res.scoreRankList;
          console.log(this.tongLists);
          if (this.tongLists.length > 0) {
            this.bingFen = res.toiletScore;
            let kong = this.tongLists.find((res, index) => {
              if (transferPointId == res.transferPointId) {
                this.Listcount = index + 1;
                return res;
              }
            });
            console.log(kong);
            this.tongjititle = kong.transferPointName;
            if (kong == undefined) {
              this.Listcount = 0;
            }
            console.log(this.Listcount);
          }

          let chartData1 = this.getChartData();
          let chartData2 = this.zhuLine();
          let chartData3 = this.yuanLine();

          let xMax = Math.max.apply(null, res.alarmCount);
          let yMax = Math.max.apply(null, res.cleanCount);
          //   console.log( xMax);
          // //  let yMax = Math.max(...[xnumMax, ynumMax]);
          // // 超载报警次数统计表
          chartData1.series[0].data = res.alarmCount;
          //    chartData1.series[1].data = res.monthH2S.womanCount
          if (xMax > 5) {
            chartData1.yAxis.max = xMax;
          } else {
            chartData1.yAxis.max = 5;
          }

          this.ChartData1 = chartData1;
          //   //清运次数统计表
          chartData2.series[0].data = res.cleanCount;
          //    chartData2.series[1].data = res.monthNH3.womanCount
          chartData2.yAxis.max = yMax;
          this.ChartData2 = chartData2;
          //    //当月转运站分数排名
          //   chartData3.series[0].data[0].value = res.toiletScore.score?res.toiletScore.score:0
          //   chartData3.series[0].data[0].name ='得分'
          //   chartData3.series[0].data[1].value = res.toiletScore.total-res.toiletScore.score
          //   chartData3.series[0].data[1].name ='未得'
          for (let i = 0; i <= res.alarmProcessingTimeCount.length; i++) {
            chartData3.series[0].data[i].value =
              res.alarmProcessingTimeCount[i];
          }
          console.log(chartData3);
          this.ChartData3 = chartData3;
          //   console.log(chartData3);
        });
    },
    //
    // async getWashRoomChartData(time = "2021-5-13", id = 1, type = 1) {
    //   const query = {
    //     params: {
    //       dateTime: time,
    //       toiletId: id,
    //       type,
    //     },
    //   };
    //   let data = await axios.get("/toiletRealDay/findToilet/dayReport", query);
    //   if (data) {
    //     let xLabel = data.manToiletDayReport.map((item) => {
    //       return item.hour;
    //     });
    //     let manValue = data.manToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let womenValue = data.womanToiletDayReport.map((item) => {
    //       return type === 1 ? item.ammoniaGas : item.ydrothion;
    //     });
    //     let selTime = new Date(...time.split("-")).getTime();
    //     let curTime = new Date(...this.curDay.split("-")).getTime();
    //     if (selTime > curTime) {
    //       manValue = [];
    //       womenValue = [];
    //     }
    //     if (selTime === curTime) {
    //       let hour = new Date().getHours();
    //       let index = xLabel.indexOf(hour) + 1;
    //       manValue.splice(index);
    //       womenValue.splice(index);
    //     }
    //     console.log(manValue);
    //     console.log(womenValue);
    //     let xnumMax = Math.max(...manValue);
    //     let ynumMax = Math.max(...womenValue);
    //     let xMax = Math.max(...[xnumMax, ynumMax, this.ammoniaGas]);
    //     let yMax = Math.max(...[xnumMax, ynumMax, this.ydrothion]);
    //     let chartData = this.getChartData();
    //    // chartData.xAxis.data = xLabel;
    //     chartData.series[0].data = manValue;
    //     // chartData.series[1].data = womenValue
    //     if (type === 1) {
    //       let xAxis = chartData;

    //       //xAxis.series[1].markLine.data[0].yAxis=this.ammoniaGas
    //       //  xAxis.series[1].markLine.label.formatter=this.ammoniaGas
    //       // splitNumber: 2,
    //       xAxis.yAxis.max = xMax;
    //       // xAxis.yAxis.interval=0.1
    //       this.ChartData1 = chartData;
    //       this.ChartData2 = this.zhuLine();
    //       this.ChartData3 = this.yuanLine();
    //     } else {
    //       let yAxis = chartData;
    //       //  yAxis.series[1].markLine.data[0].yAxis=this.ydrothion
    //       // yAxis.series[1].markLine.label.formatter=this.ydrothion
    //       // splitNumber: 2,
    //       yAxis.yAxis.max = yMax;
    //       //yAxis.yAxis.interval=0.1
    //       this.ChartData2 = yAxis;
    //       console.log(chartData);
    //     }
    //   }
    // },
    // 饼图
    yuanLine() {
      return {
        legend: {
          orient: "vertical",
          right: 10,
          top: 30,
          formatter: function (name) {
            return name;
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: ["0%", "0%"],
        },
        color: [
          " #91CD76",
          "#76CDBC",
          "#FAC958",
          "#5077F3",
          "#EF6765",
          "#F2E87E",
          "#73BFDF",
          "rgb(38, 194, 85)",
          "#F98F39",
          "#C760F7",
          "#38F1C3",
          "#FF6AB0",
        ],
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "超载处理时长",
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
            type: "pie",
            radius: [30, 60],
            center: ["30%", "50%"],
            data: [
              { value: 0, name: "1月" },
              { value: 0, name: "2月" },
              { value: 0, name: "3月" },
              { value: 0, name: "4月" },
              { value: 0, name: "5月" },
              { value: 0, name: "6月" },
              { value: 0, name: "7月" },
              { value: 0, name: "8月" },
              { value: 0, name: "9月" },
              { value: 0, name: "10月" },
              { value: 0, name: "11月" },
              { value: 0, name: "12月" },
            ],
          },
        ],
      };
    },
    //柱状图
    zhuLine() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: 45,
          right: 40,
          bottom: 50,
          top: 15,
        },
        xAxis: [
          {
            name: "月",
            nameTextStyle: {
              color: "#333",
              fontSize: 12,
            },
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },
            // splitLine: {
            //   lineStyle: {
            //     type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
            //     color: "#0096A8",
            //   },
            // },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              // rotate: 45,
              //  interval:0,
              // margin: 8,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                //   color: "#DEE7FF",
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
        ],
        yAxis: [
          {
            max: function (value) {
              //取最大值向上取整为最大刻度
              let num;
              if (value.max < 10) {
                console.log(value);
                num = 10 - value.max;
                return Math.ceil(value.max + num);
              } else if (value.max > 99) {
                console.log(value.max);
                return Math.ceil(value.max / 100) * 100;
              } else {
                console.log(Math.ceil(value.max / 10) * 10);
                return Math.ceil(value.max / 10) * 10;
              }
            },
            type: "value",

            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                // color: "#0096A8",
              },
            },
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#333",
                width: 1,
              },
            },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              rotate: 0,
              margin: 8,
              // formatter: null,
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: "#333",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: true,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: "#FF6935",
              },
            },
            label: {
              show: true,
              position: "inside",
              color: "#fff",
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            data: [20, 10, 101, 5, 8, 6, 5, 7, 6, 5, 4, 8],
          },
        ],
      };
      // this.$http
      //   .post(
      //     "main/listCarOut",
      //     this.$qs.stringify({
      //       warningtime: this.fromTimeN,
      //       depart: this.fromDepartN,
      //       cartype: this.cartypeTow,
      //     })
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     //data = res;
      //     option.series[0].data = res.data; //已处理

      //   });
    },
    // 折线图
    getChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 45,
          right: 35,
          bottom: 20,
          top: 25,
        },
        xAxis: {
          name: "月",
          nameTextStyle: {
            color: "#333",
            fontSize: 12,
          },
          type: "category",
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 10,
          splitNumber: 5,
          minInterval: 1,

          boundaryGap: [1, 1],
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "报警",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [34, 90, 20, 20, 20, 12, 11, 14, 90, 21, 23, 46],
            itemStyle: { normal: { label: { show: true } } },
          },
          // {
          //     name: '女厕',
          //     type: 'line',
          //     lineStyle: {
          //         color: "#FF8395",
          //         width: 3,
          //     },
          //     color: "#FF8395",
          //     data: [10, 12, 11, 14, 9, 30, 10,20, 32, 11, 34, 90, 20, 20,20, 12, 11, 14, 90, 23, 21,23,46,24].reverse(),
          //     markLine: {
          //         label:{
          //           position:'middle',
          //           formatter:"5",
          //           fontSize:'16'
          //       },
          //         animation: false,
          //         data: [
          //             {yAxis: 5,}
          //         ],
          //         symbol: "none",
          //         silent: true,
          //         lineStyle: {
          //             type:"solid",
          //             width: 3,
          //             color: "#FF5D31"
          //         }
          //     }
          // },
        ],
        id: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.StationscoreBox {
  width: 100%;
  ::v-deep.el-dialog__header {
    padding: 0;
    background: red;
  }
  ::v-deep .el-dialog__body {
    background: #f2f6fc;
  }
  ::v-deep .el-dialog__footer {
    background: #f2f6fc;
  }
}

.boxTitle {
  font-size: 22px;
  font-weight: bold;
  color: #3b7fff;
}
.box {
  padding-top: 20px;
  height: 500px;
  display: flex;
  flex-direction: column;
  .boxOne {
    display: flex;
    flex: 1;
    .boxOne1 {
      background: #ffffff;
      flex: 1;
      .chart-item {
        // flex: auto;
        width: 100%;
        height: 100%;
      }
    }
    .boxOne2 {
      margin-left: 10px;
      background: #ffffff;
      flex: 1;
    }
  }
  .boxTwo {
    padding-top: 10px;
    display: flex;
    flex: 1;
    .boxTwo1 {
      background: #ffffff;
      flex: 1;
    }
    .boxTwo2 {
      position: relative;
      margin-left: 10px;
      background: #ffffff;
      flex: 2;
    }
  }
}
.boxOneTitle {
  padding: 10px 20px 0px;
  .boxOneTest {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .boxOneNum {
    float: right;
    font-size: 18px;
    font-weight: bold;
    padding-right: 20px;
    color: #f94c1d;
  }
}

// 排名
.paiMing {
  width: 100%;
  height: 160px;
  display: flex;
  overflow: hidden;
  .paiBox {
    margin: 20px 0px 0px 20px;
    overflow: scroll;
    height: 160px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .paiMian {
      padding: 0px 0px 0px 0px;
      width: 170px;
      height: 50px;
      // width: 50px;
      display: flex;
      align-items: center;

      // flex-direction: column;
      .paiNum {
        margin-left: 5px;
        text-align: center;
        width: 22px;
        height: 20px;
        border-radius: 50%;
        background: #3b7fff;
        color: white;
      }
      .paiText {
        padding: 0px 20px 0px;
      }
    }
  }
}

.dang {
  position: absolute;
  bottom: 8px;
  width: 700px;
  height: 20px;
  background: #ffffff;
}
.shu1,
.shu2,
.shu3 {
  width: 2px;
  height: 140px;
  background: #ebeff5;
  position: absolute;
  top: 25%;
  left: 24%;
}
.shu2 {
  left: 47%;
}
.shu3 {
  left: 70%;
}
</style>
